<template>
  <div class="rcc-table-list logs">
    <div class="rcc-table-list__table-wrapper">
      <rcc-table
        :page.sync="page"
        :pageCount="pagesCount"
        :itemsPerPage="rowsOnTable"
        :headers="headers"
        :rows="rows"
        :is-fixed-header="true"
        :isLoading="isDataLoading"
        @filters-changed="handleFiltersChanged"
      >
        <template #message-cell="{ value }">
          <div class="message">{{ value }}</div>
        </template>
      </rcc-table>
    </div>
  </div>
</template>

<script>
import { arrayToOptions } from '@/utils/get-options'

import Page from '@/mixins/page'
import TableList from '@/mixins/table-list'
import headers from './headers'
import ProcessLogApi from '@/api/process-log'

import RccTable from 'Components/table/rcc-table'

export default {
  components: { RccTable },

  mixins: [Page, TableList, ProcessLogApi],

  data() {
    return {
      headerData: headers
    }
  },

  computed: {
    headerFiltersData() {
      return [
        {
          filter: 'manager_fio',
          name: 'manager_id',
          callback: this.$apiMethods.managers.list,
          type: 'autocomplete'
        }
      ]
    }
  },

  created() {
    this.initFilters()
  },

  methods: {
    loadData(filters) {
      this.isDataLoading = true

      this.$apiMethods.logs.paginatedList({ page: this.page, per_page: this.rowsOnTable, ...filters })
        .then(data => {
          if (data === null) {
            this.pagesCount = 1
            this.rows = []
            return
          }

          this.pagesCount = data.pages
          this.rows = data.items
        })
        .finally(() => { this.isDataLoading = false })
    },

    initFilters() {
      this.headerFiltersData.forEach(filter => {
        this.setFilter(filter)
      })
    },

    setFilter({ filter, name, callback, type = 'multiple-autocomplete' }) {
      const index = this.headerData.findIndex(item => item.value === filter)

      if (index < 0) {
        return
      }

      this.headerData[index].customFilter.name = name

      if (type === 'multiple-autocomplete') {
        this.headerData[index].customFilter.callback = callback.bind(this)
      } else if (type === 'list' || type === 'autocomplete') {
        callback.bind(this)().then(({ items }) => {
          if (!this.headerData[index]) {
            return
          }

          this.headerData[index].customFilter.options = items && arrayToOptions(
            { array: items, keyName: 'value', valueName: 'text' }
          ) || []
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.logs {
  .message {
    white-space: pre-wrap;
  }
}
</style>
