export default [
  {
    text: 'Эндпоинт',
    value: 'endpoint'
  },
  {
    text: 'Сообщение',
    value: 'message'
  },
  {
    text: 'Менеджер',
    value: 'manager_fio',
    customFilter: {
      type: 'autocomplete',
      label: 'Поиск по менеджеру'
    }
  },
  {
    text: 'Email',
    value: 'email',
    width: '300px'
  }
]
